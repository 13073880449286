// // import React from 'react'
// // import Image from '../components/formmatmaster.png';

// // const Footer = () => {
// //   return (
// //     <>
// //       <footer className="bg-black p-6 mt-10 ">
// //         <div className='flex'>

// //           <div className='w-[30%] flex justify-center items-center'>
// //             <div className="w-25 h-20  bg-cover  ">
// //               <img src={Image} alt="Profile" className="w-full h-full object-cover" />
// //             </div>
// //           </div>

// //           <div className="flex w-[70%] ">

// //             <div className="flex-1  m-4">
// //               <h3 className=" font-semibold text-gray-400">Online Converter Free</h3>
// //               <ul className="mt-2 space-y-1">
// //                 <li><a href="" className="text-white font-extralight">Formats</a></li>
// //               </ul>
// //             </div>


// //             <div className="flex-1 min-w-[200px] m-4">
// //               <h3 className=" font-semibold text-gray-400">Company</h3>
// //               <ul className="mt-2 space-y-1">
// //                 <li><a href="" className="text-white font-extralight">About </a></li>
// //                 <li><a href="" className="text-white font-extralight">FAQ </a></li>
// //               </ul>
// //             </div>

// //             <div className="flex-1 min-w-[200px] m-4">
// //               <h3 className=" font-semibold text-gray-400">Privacy and Policy</h3>
// //               <ul className="mt-2 space-y-1">
// //                 <li><a href="" className="text-white font-extralight">Privacy and Policy</a></li>
// //               </ul>
// //             </div>

// //             <div className="flex-1 min-w-[200px] m-4">
// //               <h3 className=" font-semibold text-gray-400">Resources</h3>
// //               <ul className="mt-2 space-y-1">
// //                 <li><a href="" className="text-white font-extralight">Blog</a></li>
// //               </ul>
// //             </div>

// //           </div>
// //         </div>
// //         <div className='bg-gray-400 h-[1px] mt-6'></div>
// //         <div className="mt-6 flex justify-center">
// //           <p className="text-sm text-gray-600">&copy; 2024 formatmagic.com, All right reserved.</p>
// //         </div>
// //       </footer>


// //     </>
// //   )
// // }

// // export default Footer





// import React from 'react';
// import Image from '../components/formmatmaster.png';

// const Footer = () => {
//   return (
//     <footer className="bg-black p-6 mt-10">
//       <div className="flex flex-col md:flex-row">
//         {/* Image Section */}
//         <div className="flex justify-center items-center mb-6 md:mb-0 md:w-1/3">
//           <img src={Image} alt="Profile" className="w-24 h-16 object-cover" />
//         </div>

//         {/* Links Section */}
//         <div className="flex flex-col md:flex-row md:w-2/3">
//           {/* Flexbox container to stack sections horizontally on larger screens */}
//           <div className="flex flex-wrap md:flex-nowrap">
//             {/* Individual sections */}
//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0 md:mx-4">
//               <h3 className="font-semibold text-gray-400">Online Converter Free</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">Formats</a></li>
//               </ul>
//             </div>

//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0 md:mx-4">
//               <h3 className="font-semibold text-gray-400">Company</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">About</a></li>
//                 <li><a href="#" className="text-white font-light hover:underline">FAQ</a></li>
//               </ul>
//             </div>

//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0 md:mx-4">
//               <h3 className="font-semibold text-gray-400">Privacy and Policy</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">Privacy and Policy</a></li>
//               </ul>
//             </div>

//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0 md:mx-4">
//               <h3 className="font-semibold text-gray-400">Resources</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">Blog</a></li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="bg-gray-400 h-[1px] mt-6"></div>

//       <div className="mt-6 flex justify-center">
//         <p className="text-sm text-gray-600">&copy; 2024 formatmagic.com, All rights reserved.</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


// import React from 'react';
// import Image from '../components/image.png';

// const Footer = () => {
//   return (
//     <footer className="bg-black p-6 mt-10">
//       <div className="flex flex-col md:flex-row md:justify-between">
//         {/* Image Section */}
//         <div className="flex justify-center items-center mb-6 md:mb-0 md:w-1/3">
//           <img src={Image} alt="Profile" className="w-24 h-16 object-cover" />
//         </div>

//         {/* Links Section */}
//         <div className="flex flex-col md:flex-row md:w-2/3 md:space-x-8">
//           {/* Flexbox container to stack sections horizontally on larger screens */}
//           <div className="flex flex-wrap justify-center md:justify-start">
//             {/* Individual sections */}
//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
//               <h3 className="font-semibold text-gray-400">Online Converter Free</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">Formats</a></li>
//               </ul>
//             </div>

//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
//               <h3 className="font-semibold text-gray-400">Company</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">About</a></li>
//                 <li><a href="#" className="text-white font-light hover:underline">FAQ</a></li>
//               </ul>
//             </div>

//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
//               <h3 className="font-semibold text-gray-400">Privacy and Policy</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">Privacy and Policy</a></li>
//               </ul>
//             </div>

//             <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
//               <h3 className="font-semibold text-gray-400">Resources</h3>
//               <ul className="mt-2 space-y-1">
//                 <li><a href="#" className="text-white font-light hover:underline">Blog</a></li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="bg-gray-400 h-[1px] mt-6"></div>

//       <div className="mt-6 flex justify-center">
//         <p className="text-sm text-gray-600">&copy; 2024 formatmagic.com, All rights reserved.</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;









import React from 'react';
import Image from '../assets/image.png';

const Footer = () => {
  return (
    <footer className="bg-black p-6 mt-10">
      <div className="flex flex-col md:flex-row md:justify-between">
        {/* Image Section */}
        <div className="flex justify-center items-center mb-6 md:mb-0 md:w-1/3">
          <img src={Image} alt="Profile" className="w-24 h-16 object-cover" />
        </div>

        {/* Links Section */}
        <div className="flex flex-col md:flex-row md:w-2/3 md:space-x-8">
          <div className="flex flex-wrap justify-center md:justify-start">
            <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
              <h3 className="font-semibold text-gray-400">Online Converter Free</h3>
              <ul className="mt-2 space-y-1">
                <li><a href="#" className="text-white font-light hover:underline">Formats</a></li>
              </ul>
            </div>

            <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
              <h3 className="font-semibold text-gray-400">Company</h3>
              <ul className="mt-2 space-y-1">
                <li><a href="#" className="text-white font-light hover:underline">About</a></li>
                <li><a href="#" className="text-white font-light hover:underline">FAQ</a></li>
              </ul>
            </div>

            <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
              <h3 className="font-semibold text-gray-400">Privacy and Policy</h3>
              <ul className="mt-2 space-y-1">
                <li><a href="#" className="text-white font-light hover:underline">Privacy and Policy</a></li>
              </ul>
            </div>

            <div className="flex-1 min-w-[200px] mb-6 md:mb-0">
              <h3 className="font-semibold text-gray-400">Resources</h3>
              <ul className="mt-2 space-y-1">
                <li><a href="#" className="text-white font-light hover:underline">Blog</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-400 h-[1px] mt-6"></div>

      <div className="mt-6 flex justify-center">
        <p className="text-sm text-gray-600">&copy; 2024 formatmagic.com, All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
