import Audiomerge from "./components/audiomerge";
import Audiotvideo from "./components/audiotvideo";
import Header from "./components/header";
import Homepage from "./components/homepage";
import {Routes,Route} from 'react-router-dom'
import Videotaudio from "./components/videotaudio";



function App() {
  return (
    <div className="">
       {/* <Header/>  */}

       {/* <Homepage/>  */}
      {/* <Audiotvideo/>  */}
      {/* <Audiomerge/> */}

      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/audio-merge" element={<Audiomerge/>}/>
        <Route path="/audio-to-video" element={<Audiotvideo/>}/>
        <Route path='/video-to-audio' element={<Videotaudio/>}/>

      </Routes>
    </div>
      
  );
}

export default App;
