import React from 'react'
import Headers from './headers'
import Footer from './footer'
import Image from '../assets/bg-image.jpeg';

const Videotaudio = () => {
    return (
        <div>
            <div>


                <div>
                    <Headers />
                    <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                        {/* Container for content */}
                        <div className="relative h-auto w-full  rounded-md overflow-hidden">
                            {/* Background image with opacity */}
                            <div
                                className="absolute inset-0"
                                style={{
                                    backgroundImage: `url(${Image})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    opacity: 0.3,
                                    zIndex: -1,
                                }}
                            />
                            <div className="relative z-10 py-8 px-4">
                                <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
                                    Online Video To Audio Converter
                                </div>
                                <div className="h-[2px] w-full bg-gray-500 my-4"></div>
                                <div className="py-5">
                                    <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
                                        <tbody>
                                            <tr>
                                                <td className="text-xl font-normal text-start font-lexend text-[#313131]" style={{ color: '#313131' }} >
                                                    Video File
                                                </td>
                                                <td className="flex items-center gap-4">
                                                    <button className='px-4 py-2 text-white rounded-md ' style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}>
                                                        Choose Files
                                                    </button>
                                                    <div>No Chosen Files</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                                                    Select Background Type
                                                </td>
                                                <td className="flex items-center gap-4">
                                                    <select
                                                        className="px-4 py-2 text-white rounded-md"
                                                        style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                        
                                                    >
                                                        <option value="none">None</option>
                                                        <option value="red">mp4</option>
                                                        <option value="blue">mp6</option>
                                                        <option value="green">mp7</option>
                                                    </select>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <div className="mt-10 flex justify-center">
                                    <button className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold' style={{ fontFamily: 'Montserrat' }}>
                                        Converter
                                    </button>
                                </div>

                                <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
                                    How To Use:
                                </div>

                                <div className="mt-4 px-10">
                                    <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                                        <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
                                        <li>Click the "Convert" button to start uploading your files.</li>
                                        <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
                                        <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
                                    </ol>
                                </div>

                                <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
                                    Remarks:
                                </div>

                                <div className="mt-4 px-10">
                                    <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                                        <li>The maximum upload file size is 200 MB</li>
                                        <li>Before uploading, please make sure you agree to the terms of this website</li>
                                        <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
                                        <li>This converter cannot support encrypted or protected audio files.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />

            </div>

        </div>
    )
}

export default Videotaudio
