// import React from 'react'
// import Header from './header'
// import Image from '../assets/bg-image.jpeg'
// import Footer from './footer'
// import Headers from './headers'
// const Audiomerge = () => {
//     return (
        // <div>


        //     <div>
        //         <Headers />
        //         <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
        //             {/* Container for content */}
        //             <div className="relative h-auto w-full  rounded-md overflow-hidden">
        //                 {/* Background image with opacity */}
        //                 <div
        //                     className="absolute inset-0"
        //                     style={{
        //                         backgroundImage: `url(${Image})`,
        //                         backgroundSize: 'cover',
        //                         backgroundPosition: 'center',
        //                         opacity: 0.3,
        //                         zIndex: -1,
        //                     }}
        //                 />
        //                 <div className="relative z-10 py-8 px-4">
        //                     <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
        //                         Online Audio Merge
        //                     </div>
        //                     <div className="h-[2px] w-full bg-gray-500 my-4"></div>
        //                     <div className="py-5">
        //                         <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
        //                             <tbody>
        //                                 <tr>
        //                                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
        //                                         Audio File 1
        //                                     </td>
        //                                     <td className="flex items-center gap-4">
        //                                         <button className='px-4 py-2 text-white rounded-md' style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}>
        //                                             Choose Files
        //                                         </button>
        //                                         <div>No Chosen Files</div>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
        //                                         Audio File 2
        //                                     </td>
        //                                     <td className="flex items-center gap-4">
        //                                         <button className='px-4 py-2 text-white rounded-md' style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}>
        //                                             Choose Files
        //                                         </button>
        //                                         <div>No Chosen Files</div>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
        //                                         More Audio ?
        //                                     </td>
        //                                     <td className="flex items-center gap-4">
        //                                         <button className='px-4 py-2 text-white rounded-md' style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}>
        //                                             Add More Audio
        //                                         </button>
        //                                         <div>No Chosen Files</div>
        //                                     </td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                     </div>

        //                     <div className="mt-10 flex justify-center">
        //                         <button className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold' style={{ fontFamily: 'Montserrat' }}>
        //                             Converter
        //                         </button>
        //                     </div>

        //                     <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
        //                         How To Use:
        //                     </div>

        //                     <div className="mt-4 px-10">
        //                         <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
        //                             <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
        //                             <li>Click the "Convert" button to start uploading your files.</li>
        //                             <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
        //                             <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
        //                         </ol>
        //                     </div>

        //                     <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
        //                         Remarks:
        //                     </div>

        //                     <div className="mt-4 px-10">
        //                         <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
        //                             <li>The maximum upload file size is 200 MB</li>
        //                             <li>Before uploading, please make sure you agree to the terms of this website</li>
        //                             <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
        //                             <li>This converter cannot support encrypted or protected audio files.</li>
        //                         </ol>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>


        //     <Footer/>

        // </div>
//     )
// }

// export default Audiomerge











































import React, { useState } from 'react';
import Headers from './headers';
import Image from '../assets/bg-image.jpeg';
import Footer from './footer';

const Audiomerge = () => {
    const [audioFile1, setAudioFile1] = useState(null);
    const [audioFile2, setAudioFile2] = useState(null);
    const [additionalAudioFiles, setAdditionalAudioFiles] = useState([]);
    const [isConverted, setIsConverted] = useState(false);

    const randomSongUrl = 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'; // Replace this with your desired song URL

    const handleFileChange = (event, setFile) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleAdditionalFileChange = (event) => {
        const files = Array.from(event.target.files);
        setAdditionalAudioFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleConvert = () => {
        console.log("Audio File 1:", audioFile1);
        console.log("Audio File 2:", audioFile2);
        console.log("Additional Audio Files:", additionalAudioFiles);
        setIsConverted(true);
    };

    return (
        <div>
            <Headers />
            <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                {/* Container for content */}
                <div className="relative h-auto w-full rounded-md overflow-hidden">
                    {/* Background image with opacity */}
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.3,
                            zIndex: -1,
                        }}
                    />
                    <div className="relative z-10 py-8 px-4">
                        <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
                            Online Audio Merge
                        </div>
                        <div className="h-[2px] w-full bg-gray-500 my-4"></div>
                        <div className="py-5">
                            <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
                                <tbody>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{color:'#313131'}}>
                                            Audio File 1
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="audio/*"
                                                id="audio-file-1"
                                                className="hidden"
                                                onChange={(e) => handleFileChange(e, setAudioFile1)}
                                            />
                                            <button
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                onClick={() => document.getElementById('audio-file-1').click()}
                                            >
                                                Choose Files
                                            </button>
                                            <div>{audioFile1 ? audioFile1.name : 'No Chosen Files'}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{color:'#313131'}}>
                                            Audio File 2
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="audio/*"
                                                id="audio-file-2"
                                                className="hidden"
                                                onChange={(e) => handleFileChange(e, setAudioFile2)}
                                            />
                                            <button
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                onClick={() => document.getElementById('audio-file-2').click()}
                                            >
                                                Choose Files
                                            </button>
                                            <div>{audioFile2 ? audioFile2.name : 'No Chosen Files'}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{color:'#313131'}}>
                                            More Audio?
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="audio/*"
                                                id="additional-audio-files"
                                                className="hidden"
                                                multiple
                                                onChange={handleAdditionalFileChange}
                                            />
                                            <button
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                onClick={() => document.getElementById('additional-audio-files').click()}
                                            >
                                                Add More Audio
                                            </button>
                                            <div>
                                                {additionalAudioFiles.length > 0
                                                    ? additionalAudioFiles.map((file, index) => (
                                                        <div key={index}>{file.name}</div>
                                                    ))
                                                    : 'No Chosen Files'}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10 flex justify-center">
                            {!isConverted ? (
                                <button
                                    className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold'
                                    style={{ fontFamily: 'Montserrat' }}
                                    onClick={handleConvert}
                                >
                                    Convert
                                </button>
                            ) : (
                                <div className="flex flex-col items-center">
                                    <audio controls className="mb-4 ">
                                        <source  src={randomSongUrl} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                    <button
                                        className='bg-blue-500 px-14 py-2 text-white rounded-md font-semibold'
                                        style={{ fontFamily: 'Montserrat' }}
                                    >
                                        Download
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
                            How To Use:
                        </div>

                        <div className="mt-4 px-10">
                            <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                                <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
                                <li>Click the "Convert" button to start uploading your files.</li>
                                <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
                                <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
                            </ol>
                        </div>

                        <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
                            Remarks:
                        </div>

                        <div className="mt-4 px-10">
                            <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                                <li>The maximum upload file size is 200 MB</li>
                                <li>Before uploading, please make sure you agree to the terms of this website</li>
                                <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
                                <li>This converter cannot support encrypted or protected audio files.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Audiomerge;

