
import React, { useState } from 'react';
import Image from '../assets/image.png';
import { RiArrowDropDownLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsDropdownOpen(false); // Close the dropdown after navigation
  };
  return (
    <div className='flex flex-col md:flex-row items-center justify-start gap-32 h-[75px] px-4 md:px-36 font-roboto' style={{ backgroundColor: "#000000", color: '#FFFFFF' }}>
      {/* Image */}
      <div className="h-[75px] w-[90px] p-1">
        <img src={Image} alt="format-image" className="h-10 md:h-16" />
      </div>
      {/* Navigation Links */}
      <div className="flex flex-col md:flex-row items-center gap-12 mt-4 md:mt-0 text-sm md:text-md">
        <div className="flex flex-col md:flex-row items-center mt-4 md:mt-0 text-sm md:text-md relative">
          <div
          className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300 cursor-pointer flex justify-center items-center "
            onClick={toggleDropdown}
          >
            <div className="">Converters</div>
            <RiArrowDropDownLine size={32}/>
          </div>
          {isDropdownOpen && (
            <div
              className="absolute top-full mt-2 bg-white text-white rounded shadow-md z-20"
              style={{ fontFamily: 'roboto' }}
            >
            <div
                className="px-4 py-2 cursor-pointer w-[250px] h-[40px] bg-[#AAABAB] hover:bg-black"
                onClick={() => handleNavigation('/')}
              >
                Homepage
              </div>
              <div
                className="px-4 py-2 cursor-pointer w-[250px] h-[40px] bg-[#AAABAB] hover:bg-black"
                onClick={() => handleNavigation('/audio-to-video')}
              >
                Audio-To-video-Converter
              </div>
              <div
                className="px-4 py-2 cursor-pointer w-[250px] h-[40px] bg-[#AAABAB] hover:bg-black"
                onClick={() => handleNavigation('/audio-merge')}
              >
                Audio-Merge
              </div>
              <div
                className="px-4 py-2 cursor-pointer w-[250px] h-[40px] bg-[#AAABAB] hover:bg-black"
                onClick={() => handleNavigation('/video-to-audio')}
              >
                Video-To-Audio
              </div>
              <div
                className="px-4 py-2 cursor-pointer w-[250px] h-[40px] bg-[#AAABAB] hover:bg-black"
              >
                Audio-Cut
              </div>
            </div>

          )}
        </div>
        <div className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300">
        <div
          className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300 cursor-pointer flex justify-center items-center gap-2"
            onClick={toggleDropdown}
          >
            <div className="">About Us</div>
            <RiArrowDropDownLine size={32}/>
          </div>
        </div>
        <div className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300">Blogs</div>
      </div>
    </div>
  );
};

export default Header;