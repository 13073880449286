// import React from 'react'
// import Image1 from '../assets/buttonimage1.png'
// import { useNavigate } from 'react-router-dom'


// const Servicebuttons = () => {
//     const navigate = useNavigate()

//     const handleAudioToVideo = () => {
//         navigate('/audio-to-video')
//     }
//     return (
//         <div>

//             <div className="mt-4">
//                 <div className="flex justify-center">
//                     <div className="grid grid-cols-3 gap-8 w-[90%]" style={{fontFamily:'roboto'}}>
//                         <div className="flex justify-center">
//                             <button className='relative flex justify-between items-center w-full  text-white py-4 px-2 rounded-full' style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }} onClick={handleAudioToVideo}>
//                                 <div className="flex gap-4 items-center">
//                                     <div className="bg-white rounded-full">
//                                         <img className='h-8 w-8 rounded-full' src={Image1} alt="icon" />
//                                     </div>
//                                     <div className="text-xs py-2">AUDIO TO VIDEO</div>
//                                 </div>
//                                 <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
//                                     <select className="bg-white text-black font-md text-sm rounded-full h-[35px]  px-2 py-1 border-none">
//                                         <option value="option1">Select Target Format</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </button>

//                         </div>
//                         <div className="flex justify-center">
//                             <button className='relative flex justify-between items-center w-full bg-gradient-to-r from-gray-300 to-gray-400 text-white py-4 px-2 rounded-full' style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}>
//                                 <div className="flex gap-4 items-center">
//                                     <div className="bg-white rounded-full">
//                                         <img className='h-8 w-8 rounded-full' src={Image1} alt="icon" />
//                                     </div>
//                                     <div className="text-xs py-2">VIDEO TO AUDIO</div>
//                                 </div>
//                                 <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
//                                     <select className="bg-white text-black font-md text-sm rounded-full h-[35px]  px-2 py-1 border-none">
//                                         <option value="option1">Select Target Format</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </button>

//                         </div>
//                         <div className="flex justify-center">
//                             <button className='relative flex justify-between items-center w-full bg-gradient-to-r from-gray-300 to-gray-400 text-white py-4 px-2 rounded-full' style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}>
//                                 <div className="flex gap-4 items-center">
//                                     <div className="bg-white rounded-full">
//                                         <img className='h-8 w-8 rounded-full' src={Image1} alt="icon" />
//                                     </div>
//                                     <div className="text-xs py-2">MERGE AUDIO</div>
//                                 </div>
//                                 <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
//                                     <select className="bg-white text-black font-md text-sm rounded-full h-[35px]  px-2 py-1 border-none">
//                                         <option value="option1">Select Target Format</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </button>

//                         </div>
//                         <div className="flex justify-center">
//                             <button className='relative flex justify-between items-center w-full bg-gradient-to-r from-gray-300 to-gray-400 text-white py-4 px-2 rounded-full' style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}>
//                                 <div className="flex gap-4 items-center">
//                                     <div className="bg-white rounded-full">
//                                         <img className='h-8 w-8 rounded-full' src={Image1} alt="icon" />
//                                     </div>
//                                     <div className="text-xs py-2">CUT AUDIO</div>
//                                 </div>
//                                 <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
//                                     <select className="bg-white text-black font-md text-sm rounded-full h-[35px]  px-2 py-1 border-none">
//                                         <option value="option1">Select Target Format</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </button>

//                         </div>
//                         <div className="flex justify-center">
//                             <button className='relative flex justify-between items-center w-full bg-gradient-to-r from-gray-300 to-gray-400 text-white py-4 px-2 rounded-full' style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}>
//                                 <div className="flex gap-4 items-center">
//                                     <div className="bg-white rounded-full">
//                                         <img className='h-8 w-8 rounded-full' src={Image1} alt="icon" />
//                                     </div>
//                                     <div className="text-xs py-2">INCREASE VOLUME</div>
//                                 </div>
//                                 <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
//                                     <select className="bg-white text-black font-md text-sm rounded-full h-[35px]  px-2 py-1 border-none">
//                                         <option value="option1">Select Target Format</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </button>

//                         </div>
//                         <div className="flex justify-center">
//                             <button className='relative flex justify-between items-center w-full bg-gradient-to-r from-gray-300 to-gray-400 text-white py-4 px-2 rounded-full' style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}>
//                                 <div className="flex gap-4 items-center">
//                                     <div className="bg-white rounded-full">
//                                         <img className='h-8 w-8 rounded-full' src={Image1} alt="icon" />
//                                     </div>
//                                     <div className="text-xs py-2">COMPRESS AUDIO VIDEO</div>
//                                 </div>
//                                 <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
//                                     <select className="bg-white text-black font-md text-sm rounded-full h-[35px]  px-2 py-1 border-none">
//                                         <option value="option1">Select Target Format</option>
//                                         <option value="option2">Option 2</option>
//                                         <option value="option3">Option 3</option>
//                                     </select>
//                                 </div>
//                             </button>

//                         </div>
//                     </div>


//                 </div>
//             </div>

//         </div>
//     )
// }

// export default Servicebuttons





import React, { useState } from 'react'
import Image1 from '../assets/buttonimage1.png'
import { useNavigate } from 'react-router-dom'

const Servicebuttons = () => {
    const navigate = useNavigate()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleAudioToVideo = () => {
        navigate('/audio-to-video')



    }

    const handleButtonClick = () => {
        navigate('/audio-to-video'); // Redirect to main page or any other page
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setIsDropdownOpen(false); // Close dropdown after selecting an option
        // You can add logic here to redirect to a different page based on selected option
        switch (event.target.value) {
            case 'option1':
                navigate('/audio-to-video'); // Redirect to page 1
                break;
            case 'option2':
                navigate('/audio-to-video'); // Redirect to page 2
                break;
            case 'option3':
                navigate('/audio-to-video'); // Redirect to page 3
                break;
            default:
                break;
        }
    };


    return (
        <div>
            <div className="mt-4">
                <div className="flex justify-center">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 w-full md:w-[95%]" style={{ fontFamily: 'roboto' }}>
                        <div className="flex justify-center">
                            <button
                                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                                onClick={handleButtonClick}
                            >
                                <div className="flex gap-2 md:gap-4 items-center">
                                    <div className="bg-white rounded-full p-1 md:p-0">
                                        <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                                    </div>
                                    <div className="text-xs md:text-sm py-2">AUDIO TO VIDEO</div>
                                </div>
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                    <select
                                        className="bg-white text-black text-xs md:text-sm rounded-full h-[30px] md:h-[35px] px-2 py-1 border-none"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                                    >
                                        <option value="">Select Target Format</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </button>
                        </div>

                        <div className="flex justify-center">
                            <button
                                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                                onClick={handleButtonClick}
                            >
                                <div className="flex gap-2 md:gap-4 items-center">
                                    <div className="bg-white rounded-full p-1 md:p-0">
                                        <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                                    </div>
                                    <div className="text-xs md:text-sm py-2"> VIDEO To AUDIO</div>
                                </div>
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                    <select
                                        className="bg-white text-black text-xs md:text-sm rounded-full h-[30px] md:h-[35px] px-2 py-1 border-none"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                                    >
                                        <option value="">Select Target Format</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </button>
                        </div>

                        <div className="flex justify-center">
                        <button
                                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                                onClick={handleButtonClick}
                            >
                                <div className="flex gap-2 md:gap-4 items-center">
                                    <div className="bg-white rounded-full p-1 md:p-0">
                                        <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                                    </div>
                                    <div className="text-xs md:text-sm py-2">MERGE AUDIO</div>
                                </div>
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                    <select
                                        className="bg-white text-black text-xs md:text-sm rounded-full h-[30px] md:h-[35px] px-2 py-1 border-none"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                                    >
                                        <option value="">Select Target Format</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </button>
                        </div>

                        <div className="flex justify-center">
                        <button
                                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                                onClick={handleButtonClick}
                            >
                                <div className="flex gap-2 md:gap-4 items-center">
                                    <div className="bg-white rounded-full p-1 md:p-0">
                                        <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                                    </div>
                                    <div className="text-xs md:text-sm py-2">CUT AUDIO</div>
                                </div>
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                    <select
                                        className="bg-white text-black text-xs md:text-sm rounded-full h-[30px] md:h-[35px] px-2 py-1 border-none"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                                    >
                                        <option value="">Select Target Format</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </button>
                        </div>

                        <div className="flex justify-center">
                        <button
                                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                                onClick={handleButtonClick}
                            >
                                <div className="flex gap-2 md:gap-4 items-center">
                                    <div className="bg-white rounded-full p-1 md:p-0">
                                        <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                                    </div>
                                    <div className="text-xs md:text-sm py-2">INCREASE VOLUME</div>
                                </div>
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                    <select
                                        className="bg-white text-black text-xs md:text-sm rounded-full h-[30px] md:h-[35px] px-2 py-1 border-none"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                                    >
                                        <option value="">Select Target Format</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </button>
                        </div>

                        <div className="flex justify-center">
                        <button
                                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                                onClick={handleButtonClick}
                            >
                                <div className="flex gap-2 md:gap-4 items-center">
                                    <div className="bg-white rounded-full p-1 md:p-0">
                                        <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                                    </div>
                                    <div className="text-xs md:text-sm py-2">COMPRESS AUDIO VIDEO</div>
                                </div>
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                    <select
                                        className="bg-white text-black text-xs md:text-sm rounded-full h-[30px] md:h-[35px] px-2 py-1 border-none"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                                    >
                                        <option value="">Select Target Format</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Servicebuttons
