// // import React, { useState } from 'react';
// // import Header from './header';
// // import Image from '../assets/bg-image.jpeg'; // Ensure this path is correct and the image is properly imported
// // import Footer from './footer';

// // const Audiotvideo = () => {
// //   // State to track the selected files and background type
// //   const [selectedAudioFile, setSelectedAudioFile] = useState(null);
// //   const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);
// //   const [selectedBackgroundType, setSelectedBackgroundType] = useState('none');

// //   // Handle audio file selection
// //   const handleAudioFileChange = (event) => {
// //     const file = event.target.files[0];
// //     if (file) {
// //       setSelectedAudioFile(file);
// //     }
// //   };

// //   // Handle background file selection
// //   const handleBackgroundFileChange = (event) => {
// //     const file = event.target.files[0];
// //     if (file) {
// //       setSelectedBackgroundFile(file);
// //     }
// //   };

// //   // Handle dropdown change
// //   const handleBackgroundTypeChange = (event) => {
// //     setSelectedBackgroundType(event.target.value);
// //   };

// //   return (
// //     <div>
// //       <Header />
// //       <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
// //         {/* Container for content */}
// //         <div className="relative h-auto w-full rounded-md overflow-hidden">
// //           {/* Background image with opacity */}
// //           <div
// //             className="absolute inset-0"
// //             style={{
// //               backgroundImage: `url(${Image})`,
// //               backgroundSize: 'cover',
// //               backgroundPosition: 'center',
// //               opacity: 0.3,
// //               zIndex: -1,
// //             }}
// //           />
// //           <div className="relative z-10 py-8 px-4">
// //             <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
// //               Online Audio To Video Converter
// //             </div>
// //             <div className="h-[2px] w-full bg-gray-500 my-4"></div>
// //             <div className="py-5">
// //               <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
// //                 <tbody>
// //                   <tr>
// //                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
// //                       Audio File
// //                     </td>
// //                     <td className="flex items-center gap-4">
// //                       <input
// //                         type="file"
// //                         accept="audio/*"
// //                         id="audio-file"
// //                         className='hidden' // Hide the file input
// //                         onChange={handleAudioFileChange}
// //                       />
// //                       <button
// //                         onClick={() => document.getElementById('audio-file').click()} // Trigger the file input
// //                         className='px-4 py-2 text-white rounded-md'
// //                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
// //                       >
// //                         Choose Files
// //                       </button>
// //                       <div>{selectedAudioFile ? selectedAudioFile.name : 'No Chosen Files'}</div>
// //                     </td>
// //                   </tr>
// //                   <tr>
// //                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
// //                       Select Background Type
// //                     </td>
// //                     <td className="flex items-center gap-4">
// //                       <select
// //                         className='px-4 py-2 text-white rounded-md'
// //                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
// //                         onChange={handleBackgroundTypeChange}
// //                         value={selectedBackgroundType}
// //                       >
// //                         <option value="none">None</option>
// //                         <option value="red">Red</option>
// //                         <option value="blue">Blue</option>
// //                         <option value="green">Green</option>
// //                         {/* Add more colors as needed */}
// //                       </select>
// //                     </td>
// //                   </tr>
// //                   <tr>
// //                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
// //                       Select Background
// //                     </td>
// //                     <td className="flex items-center gap-4">
// //                       {selectedBackgroundType === 'none' && (
// //                         <>
// //                           <input
// //                             type="file"
// //                             accept="image/*"
// //                             id="background-file"
// //                             className='hidden' // Hide the file input
// //                             onChange={handleBackgroundFileChange}
// //                           />
// //                           <button
// //                             onClick={() => document.getElementById('background-file').click()} // Trigger the file input
// //                             className='px-4 py-2 text-white rounded-md'
// //                             style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
// //                           >
// //                             Choose Files
// //                           </button>
// //                         </>
// //                       )}
// //                       <div>{selectedBackgroundFile ? selectedBackgroundFile.name : 'No Chosen Files'}</div>
// //                     </td>
// //                   </tr>
// //                 </tbody>
// //               </table>
// //             </div>

// //             <div className="mt-10 flex justify-center">
// //               <button className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold' style={{ fontFamily: 'Montserrat' }}>
// //                 Convert
// //               </button>
// //             </div>

// //             <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
// //               How To Use:
// //             </div>

// //             <div className="mt-4 px-10">
// //               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
// //                 <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
// //                 <li>Click the "Convert" button to start uploading your files.</li>
// //                 <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
// //                 <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
// //               </ol>
// //             </div>

// //             <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
// //               Remarks:
// //             </div>

// //             <div className="mt-4 px-10">
// //               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
// //                 <li>The maximum upload file size is 200 MB</li>
// //                 <li>Before uploading, please make sure you agree to the terms of this website</li>
// //                 <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
// //                 <li>This converter cannot support encrypted or protected audio files.</li>
// //               </ol>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default Audiotvideo;



// import React, { useState, useEffect } from 'react';
// import Header from './header';
// import Image from '../assets/bg-image.jpeg'; // Ensure this path is correct and the image is properly imported
// import Footer from './footer';

// const Audiotvideo = () => {
//   // State to track the selected files and background type
//   const [selectedAudioFile, setSelectedAudioFile] = useState(null);
//   const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);
//   const [selectedBackgroundType, setSelectedBackgroundType] = useState('none');

//   // State to store object URLs for previews
//   const [audioPreviewUrl, setAudioPreviewUrl] = useState('');
//   const [backgroundPreviewUrl, setBackgroundPreviewUrl] = useState('');

//   // Handle audio file selection
//   const handleAudioFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setSelectedAudioFile(file);
//       setAudioPreviewUrl(URL.createObjectURL(file));
//     }
//   };

//   // Handle background file selection
//   const handleBackgroundFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setSelectedBackgroundFile(file);
//       setBackgroundPreviewUrl(URL.createObjectURL(file));
//     }
//   };

//   // Handle dropdown change
//   const handleBackgroundTypeChange = (event) => {
//     setSelectedBackgroundType(event.target.value);
//   };

//   // Clean up object URLs on component unmount
//   useEffect(() => {
//     return () => {
//       if (audioPreviewUrl) URL.revokeObjectURL(audioPreviewUrl);
//       if (backgroundPreviewUrl) URL.revokeObjectURL(backgroundPreviewUrl);
//     };
//   }, [audioPreviewUrl, backgroundPreviewUrl]);

//   return (
//     <div >
//       <Header />
//       <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36 mb-40">
//         {/* Container for content */}
//         <div className="relative h-auto w-full rounded-md overflow-hidden">
//           {/* Background image with opacity */}
//           <div
//             className="absolute inset-0"
//             style={{
//               backgroundImage: `url(${Image})`,
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//               opacity: 0.3,
//               zIndex: -1,
//             }}
//           />
//           <div className="relative z-10 py-8 px-4">
//             <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
//               Online Audio To Video Converter
//             </div>
//             <div className="h-[2px] w-full bg-gray-500 my-4"></div>
//             <div className="py-5">
//               <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
//                 <tbody>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Audio File
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <input
//                         type="file"
//                         accept="audio/*"
//                         id="audio-file"
//                         className='hidden' // Hide the file input
//                         onChange={handleAudioFileChange}
//                       />
//                       <button
//                         onClick={() => document.getElementById('audio-file').click()} // Trigger the file input
//                         className='px-4 py-2 text-white rounded-md'
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
//                       >
//                         Choose Files
//                       </button>
//                       <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">{selectedAudioFile ? selectedAudioFile.name : 'No Chosen Files'}</div>
//                       {audioPreviewUrl && (
//                         <audio controls className="mt-2">
//                           <source src={audioPreviewUrl} type="audio/mp3" />
//                           Your browser does not support the audio element.
//                         </audio>
//                       )}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Select Background Type
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <select
//                         className='px-4 py-2 text-white rounded-md'
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
//                         onChange={handleBackgroundTypeChange}
//                         value={selectedBackgroundType}
//                       >
//                         <option value="none">None</option>
//                         <option value="red">Red</option>
//                         <option value="blue">Blue</option>
//                         <option value="green">Green</option>
//                         {/* Add more colors as needed */}
//                       </select>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Select Background
//                     </td>
//                     <td className="flex items-center gap-4">
//                       {selectedBackgroundType === 'none' && (
//                         <>
//                           <input
//                             type="file"
//                             accept="image/*"
//                             id="background-file"
//                             className='hidden' // Hide the file input
//                             onChange={handleBackgroundFileChange}
//                           />
//                           <button
//                             onClick={() => document.getElementById('background-file').click()} // Trigger the file input
//                             className='px-4 py-2 text-white rounded-md'
//                             style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
//                           >
//                             Choose Files
//                           </button>
//                         </>
//                       )}
//                       <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">{selectedBackgroundFile ? selectedBackgroundFile.name : 'No Chosen Files'}</div>
//                       {backgroundPreviewUrl && (
//                         <img
//                           src={backgroundPreviewUrl}
//                           alt="Background Preview"
//                           className="mt-2"
//                           style={{ maxWidth: '200px', maxHeight: '200px' }}
//                         />
//                       )}
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//             <div className="mt-10 flex justify-center">
//               <button className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold' style={{ fontFamily: 'Montserrat' }}>
//                 Convert
//               </button>
//             </div>

//             <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
//               How To Use:
//             </div>

//             <div className="mt-4 px-10">
//               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
//                 <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
//                 <li>Click the "Convert" button to start uploading your files.</li>
//                 <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
//                 <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
//               </ol>
//             </div>

//             <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
//               Remarks:
//             </div>

//             <div className="mt-4 px-10">
//               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
//                 <li>The maximum upload file size is 200 MB</li>
//                 <li>Before uploading, please make sure you agree to the terms of this website</li>
//                 <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
//                 <li>This converter cannot support encrypted or protected audio files.</li>
//               </ol>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Audiotvideo;
















// import React, { useState } from 'react';
// import Header from './header';
// import Image from '../assets/bg-image.jpeg'; // Ensure this path is correct and the image is properly imported
// import Footer from './footer';

// const Audiotvideo = () => {
//   // State to store an array of selected audio files
//   const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);

//   // Handle audio file selection
//   const handleAudioFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       // Update the array with the new file
//       setSelectedAudioFiles(prevFiles => {
//         const updatedFiles = [...prevFiles, file];
//         console.log("Selected Audio Files:", updatedFiles);
//         return updatedFiles;
//       });
//     }
//   };

//   const handleConvert = () => {
//     console.log("files",selectedAudioFiles)
//   }

//   return (
//     <div>
//       <Header />
//       <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36 mb-40">
//         {/* Container for content */}
//         <div className="relative h-auto w-full rounded-md overflow-hidden">
//           {/* Background image with opacity */}
//           <div
//             className="absolute inset-0"
//             style={{
//               backgroundImage: `url(${Image})`,
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//               opacity: 0.3,
//               zIndex: -1,
//             }}
//           />
//           <div className="relative z-10 py-8 px-4">
//             <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
//               Online Audio To Video Converter
//             </div>
//             <div className="h-[2px] w-full bg-gray-500 my-4"></div>
//             <div className="py-5">
//               <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
//                 <tbody>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Audio File
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <input
//                         type="file"
//                         accept="audio/*"
//                         id="audio-file"
//                         className='hidden' // Hide the file input
//                         onChange={handleAudioFileChange}
//                       />
//                       <button
//                         onClick={() => document.getElementById('audio-file').click()} // Trigger the file input
//                         className='px-4 py-2 text-white rounded-md'
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
//                       >
//                         Choose Files
//                       </button>
//                       <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
//                         {selectedAudioFiles.length > 0 
//                           ? selectedAudioFiles.map((file, index) => (
//                               <div key={index}>{file.name}</div>
//                             ))
//                           : 'No Chosen Files'}
//                       </div>
//                     </td>
//                   </tr>
//                   {/* Other UI components remain unchanged */}
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Select Background Type
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <select
//                         className='px-4 py-2 text-white rounded-md'
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
//                       >
//                         <option value="none">None</option>
//                         <option value="red">Red</option>
//                         <option value="blue">Blue</option>
//                         <option value="green">Green</option>
//                       </select>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Select Background
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <button
//                         className='px-4 py-2 text-white rounded-md'
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
//                       >
//                         Choose Files
//                       </button>
//                       <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">No Chosen Files</div>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//             <div className="mt-10 flex justify-center">
//               <button className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold' onClick={handleConvert} style={{ fontFamily: 'Montserrat' }}>
//                 Convert
//               </button>
//             </div>

//             <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
//               How To Use:
//             </div>

//             <div className="mt-4 px-10">
//               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
//                 <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
//                 <li>Click the "Convert" button to start uploading your files.</li>
//                 <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
//                 <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
//               </ol>
//             </div>

//             <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
//               Remarks:
//             </div>

//             <div className="mt-4 px-10">
//               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
//                 <li>The maximum upload file size is 200 MB</li>
//                 <li>Before uploading, please make sure you agree to the terms of this website</li>
//                 <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
//                 <li>This converter cannot support encrypted or protected audio files.</li>
//               </ol>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Audiotvideo;































// import React, { useState } from 'react';
// import Header from './header';
// import Image from '../assets/bg-image.jpeg'; // Ensure this path is correct and the image is properly imported
// import Footer from './footer';

// const Audiotvideo = () => {
//   const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
//   const [selectedBackgroundType, setSelectedBackgroundType] = useState('none');
//   const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);

//   const handleAudioFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setSelectedAudioFiles((prevFiles) => {
//         const updatedFiles = [...prevFiles, file];
//         console.log('Selected Audio Files:', updatedFiles);
//         return updatedFiles;
//       });
//     }
//   };

//   const handleBackgroundTypeChange = (event) => {
//     setSelectedBackgroundType(event.target.value);
//     setSelectedBackgroundFile(null); // Clear background file selection when changing the dropdown
//   };

//   const handleBackgroundFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setSelectedBackgroundFile(file);
//     }
//   };

//   const handleConvert = () => {
//     console.log("files",selectedAudioFiles)
//     console.log("background",selectedBackgroundFile)
//   }

//   return (
//     <div>
//       <Header />
//       <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36 mb-40">
//         {/* Container for content */}
//         <div className="relative h-auto w-full rounded-md overflow-hidden">
//           {/* Background image with opacity */}
//           <div
//             className="absolute inset-0"
//             style={{
//               backgroundImage: `url(${Image})`,
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//               opacity: 0.3,
//               zIndex: -1,
//             }}
//           />
//           <div className="relative z-10 py-8 px-4">
//             <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
//               Online Audio To Video Converter
//             </div>
//             <div className="h-[2px] w-full bg-gray-500 my-4"></div>
//             <div className="py-5">
//               <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
//                 <tbody>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Audio File
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <input
//                         type="file"
//                         accept="audio/*"
//                         id="audio-file"
//                         className="hidden" // Hide the file input
//                         onChange={handleAudioFileChange}
//                       />
//                       <button
//                         onClick={() => document.getElementById('audio-file').click()} // Trigger the file input
//                         className="px-4 py-2 text-white rounded-md"
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
//                       >
//                         Choose Files
//                       </button>
//                       <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
//                         {selectedAudioFiles.length > 0
//                           ? selectedAudioFiles.map((file, index) => <div key={index}>{file.name}</div>)
//                           : 'No Chosen Files'}
//                       </div>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                       Select Background Type
//                     </td>
//                     <td className="flex items-center gap-4">
//                       <select
//                         className="px-4 py-2 text-white rounded-md"
//                         style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
//                         onChange={handleBackgroundTypeChange}
//                         value={selectedBackgroundType}
//                       >
//                         <option value="none">None</option>
//                         <option value="red">Red</option>
//                         <option value="blue">Blue</option>
//                         <option value="green">Green</option>
//                       </select>
//                     </td>
//                   </tr>
//                   {selectedBackgroundType === 'none' && (
//                     <tr>
//                       <td className="text-xl font-semibold text-center" style={{ fontFamily: 'Lexend Deca' }}>
//                         Select Background
//                       </td>
//                       <td className="flex items-center gap-4">
//                         <input
//                           type="file"
//                           accept="image/*"
//                           id="background-file"
//                           className="hidden" // Hide the file input
//                           onChange={handleBackgroundFileChange}
//                         />
//                         <button
//                           onClick={() => document.getElementById('background-file').click()} // Trigger the file input
//                           className="px-4 py-2 text-white rounded-md"
//                           style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
//                         >
//                           Choose Files
//                         </button>
//                         <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
//                           {selectedBackgroundFile ? selectedBackgroundFile.name : 'No Chosen Files'}
//                         </div>
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>

//             <div className="mt-10 flex justify-center">
//               <button className="bg-green-500 px-14 py-2 text-white rounded-md font-semibold" onClick={handleConvert} style={{ fontFamily: 'Montserrat' }}>
//                 Convert
//               </button>
//             </div>

//             <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
//               How To Use:
//             </div>

//             <div className="mt-4 px-10">
//               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
//                 <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
//                 <li>Click the "Convert" button to start uploading your files.</li>
//                 <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
//                 <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
//               </ol>
//             </div>

//             <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
//               Remarks:
//             </div>

//             <div className="mt-4 px-10">
//               <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
//                 <li>The maximum upload file size is 200 MB</li>
//                 <li>Before uploading, please make sure you agree to the terms of this website</li>
//                 <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
//                 <li>This converter cannot support encrypted or protected audio files.</li>
//               </ol>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Audiotvideo;















































import React, { useState } from 'react';
import Headers from './headers';
import Image from '../assets/bg-image.jpeg'; // Ensure this path is correct and the image is properly imported
import Footer from './footer';


const Audiotvideo = () => {
  const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
  const [selectedBackgroundType, setSelectedBackgroundType] = useState('none');
  const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);
  const [showModal, setShowModal] = useState(false);


  // const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
  const [audioURL, setAudioURL] = useState(null);

  const handleAudioFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedAudioFiles(files);

    if (files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      setAudioURL(url);
    } else {
      setAudioURL(null);
    }
  };


  


  // const handleAudioFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setSelectedAudioFiles((prevFiles) => {
  //       const updatedFiles = [...prevFiles, file];
  //       console.log('Selected Audio Files:', updatedFiles);
  //       return updatedFiles;
  //     });
  //   }
  // };

  const handleBackgroundTypeChange = (event) => {
    setSelectedBackgroundType(event.target.value);
    setSelectedBackgroundFile(null); // Clear background file selection when changing the dropdown
  };

  const handleBackgroundFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedBackgroundFile(file);
    }
  };

  const handleConvert = () => {
    console.log('files', selectedAudioFiles);
    console.log('background', selectedBackgroundFile);
    // Trigger modal with video preview
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Headers />
      <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36 mb-40">
        {/* Container for content */}
        <div className="relative h-auto w-full rounded-md overflow-hidden">
          {/* Background image with opacity */}
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: 0.3,
              zIndex: -1,
            }}
          />
          <div className="relative z-10 py-8 px-4">
            <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
              Online Audio To Video Converter
            </div>
            <div className="h-[2px] w-full bg-gray-500 my-4"></div>
            <div className="py-5">
              <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
                <tbody>
                  <tr>
                    <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                      Audio File
                    </td>
                    <td className="flex items-center gap-4">
                      <input
                        type="file"
                        accept="audio/*"
                        id="audio-file"
                        className="hidden"
                        onChange={handleAudioFileChange}
                      />
                      <button
                        onClick={() => document.getElementById('audio-file').click()}
                        className="px-4 py-2 text-white rounded-md"
                        style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }}
                      >
                        Choose Files
                      </button>
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
                        {selectedAudioFiles.length > 0
                          ? selectedAudioFiles.map((file, index) => <div key={index}>{file.name}</div>)
                          : 'No File Chosen'}
                      </div>
                    </td>
                  </tr>
                  {audioURL && (
                    <tr>
                      <td colSpan="2" className="pt-4">
                        <audio controls src={audioURL} className="w-full">
                          Your browser does not support the audio element.
                        </audio>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                      Select Background Type
                    </td>
                    <td className="flex items-center gap-4">
                      <select
                        className="px-4 py-2 text-white rounded-md"
                        style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                        onChange={handleBackgroundTypeChange}
                        value={selectedBackgroundType}
                      >
                        <option value="none">None</option>
                        <option value="red">Red</option>
                        <option value="blue">Blue</option>
                        <option value="green">Green</option>
                      </select>
                    </td>
                  </tr>
                  {selectedBackgroundType === 'none' && (
                    <tr>
                      <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                        Select Background
                      </td>
                      <td className="flex items-center gap-4">
                        <input
                          type="file"
                          accept="image/*"
                          id="background-file"
                          className="hidden" // Hide the file input
                          onChange={handleBackgroundFileChange}
                        />
                        <button
                          onClick={() => document.getElementById('background-file').click()} // Trigger the file input
                          className="px-4 py-2 text-white rounded-md"
                          style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }} // Ensure button size is fixed
                        >
                          Choose Files
                        </button>
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
                          {selectedBackgroundFile ? selectedBackgroundFile.name : 'No File Chosen'}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="mt-10 flex justify-center">
              <button className=" px-14 py-2 text-white rounded-md font-medium border hover:border-black hover:shadow-md" onClick={handleConvert} style={{ backgroundColor: '#14AE5C' }}>
                Convert
              </button>
            </div>

            <div className="mt-16 text-2xl font-light px-10 font-lexend " style={{ color: '#313131' }}>
              How To Use:
            </div>

            <div className="mt-4 px-10">
              <ol className="list-decimal pl-6 font-lexend" >
                <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
                <li>Click the "Convert" button to start uploading your files.</li>
                <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
                <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
              </ol>
            </div>

            <div className="mt-16 text-2xl font-extralight px-10 mb-10 font-lexend" style={{ color: '#313131' }}>
              Remarks:
            </div>

            <div className="mt-4 px-10">
              <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                <li>The maximum upload file size is 200 MB</li>
                <li>Before uploading, please make sure you agree to the terms of this website</li>
                <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
                <li>This converter cannot support encrypted or protected audio files.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Modal for Video Preview */}
      {showModal && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeModal}
        >
          <div className="bg-white p-4 rounded-md shadow-md relative max-w-[90%] max-h-[90%]">
            <button
              className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
              onClick={closeModal}
            >
              &times;
            </button>
            <video controls className="w-full h-auto">
              <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default Audiotvideo;
