// import React from 'react'
// import Box1 from '../assets/box1-.png'
// import Box2 from '../assets/two.png'
// import Box3 from '../assets/third.png'
// import Box4 from '../assets/four.png'
// import Box5 from '../assets/five.png'
// import Box6 from '../assets/six.png'


// const Card = () => {
//     return (
//         <div>
//  <div className="w-full mt-32 mb-20 px-4 lg:px-40">
//             <div className="flex flex-col lg:flex-row justify-center">
//                 <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//                     {/* Grid items */}
//                     {[Box1, Box2, Box3, Box4, Box5, Box6].map((box, index) => (
//                         <div key={index} className="flex justify-center items-stretch">
//                             <div className="w-full bg-black text-white flex flex-col items-center rounded-md shadow-inner" style={{ fontFamily: 'Lexend Deca' }}>
//                                 <div className="mb-4 py-4 flex-shrink-0">
//                                     <img src={box} className="h-8 w-8" alt={`Box ${index + 1}`} />
//                                 </div>
//                                 <div className="text-sm font-bold mb-8"> {/* Replace with dynamic content if needed */}
//                                     {['10+ Formats Supported', 'Fast and Easy', 'In The Cloud', 'Custom Settings', 'Security Guaranteed', 'All Devices Supported'][index]}
//                                 </div>
//                                 <div className="text-sm px-4 text-start mt-2 py-2">
//                                     {['We support more than 25600 different conversions between more than 300 different file formats. More than any other converter.',
//                                     'Just drop your files on the page, choose an output format and click "Convert" button. Wait a little for the process to complete. We aim to do all our conversions in under 1-2 minutes.',
//                                     'All conversions take place in the cloud and will not consume any capacity from your computer.',
//                                     'Most conversion types support advanced options. For example with a video converter you can choose quality, aspect ratio, codec and other settings, rotate and flip.',
//                                     'We delete uploaded files instantly and converted ones after 24 hours. No one has access to your files and privacy is 100% guaranteed. Read more about security.',
//                                     'Convertio is browser-based and works for all platforms. There is no need to download and install any software.'][index]}
//                                 </div>
//                                 <div className="h-[80px] w-full" style={{ background: 'linear-gradient(180deg, #000000 30%, #666666 100%)', boxShadow: '0px 4px 4px 0px #00000040' }}></div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>


//         </div>
//     )
// }

// export default Card





import React from 'react'
import Box1 from '../assets/box1-.png'
import Box2 from '../assets/two.png'
import Box3 from '../assets/third.png'
import Box4 from '../assets/four.png'
import Box5 from '../assets/five.png'
import Box6 from '../assets/six.png'

const Card = () => {
    // Array of data for grid items
    const gridItems = [
        { img: Box1, title: '10+ Formats Supported', content: 'We support more than 25600 different conversions between more than 300 different file formats. More than any other converter.' },
        { img: Box2, title: 'Fast and Easy', content: 'Just drop your files on the page, choose an output format and click "Convert" button. Wait a little for the process to complete. We aim to do all our conversions in under 1-2 minutes.' },
        { img: Box3, title: 'In The Cloud', content: 'All conversions take place in the cloud and will not consume any capacity from your computer.' },
        { img: Box4, title: 'Custom Settings', content: 'Most conversion types support advanced options. For example with a video converter you can choose quality, aspect ratio, codec and other settings, rotate and flip.' },
        { img: Box5, title: 'Security Guaranteed', content: 'We delete uploaded files instantly and converted ones after 24 hours. No one has access to your files and privacy is 100% guaranteed. Read more about security.' },
        { img: Box6, title: 'All Devices Supported', content: 'Convertio is browser-based and works for all platforms. There is no need to download and install any software.' }
    ];

    return (
        <div className="w-full mt-32 mb-20 px-4 lg:px-40">
            <div className="flex flex-col lg:flex-row justify-center font-lexend">
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {gridItems.map((item, index) => (
                        <div key={index} className="flex justify-center items-stretch">
                            <div className="w-full max-w-xs bg-black text-white flex flex-col items-center rounded-md shadow-lg" style={{  height: '100%' }}>
                                {/* Image */}
                                <div className="mb-4 pt-6 flex-shrink-0">
                                    <img src={item.img} className="h-8 w-8" alt={`Box ${index + 1}`} />
                                </div>
                                {/* Title */}
                                <div className="text-sm font-bold mb-4 px-4 pb-6 text-center ">
                                    {item.title}
                                </div>
                                {/* Content */}
                                <div className="text-sm px-4 text-start mt-2 py-2 flex-grow">
                                    {item.content}
                                </div>
                                {/* Gradient Background */}
                                <div className="h-[80px] w-full" style={{ background: 'linear-gradient(180deg, #000000 30%, #666666 100%)', boxShadow: '0px 4px 4px 0px #00000040' }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Card;
